import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './HowHealthyStart.css';

function StartPage() {
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [language, setLanguage] = useState('en'); // 기본 언어를 영어로 설정
  const [email, setEmail] = useState(''); // 이메일 입력 상태 추가
  const [errorMessage, setErrorMessage] = useState(''); // 에러 메시지 상태 추가

  const startTest = () => {
    // 이메일이 비어있는 경우 에러 메시지를 설정
    // if (!email) {
    //   setErrorMessage(getEmailErrorMessage());
    //   return;
    // }

    navigate('/Question', { state: { language, email } }); // 언어 정보를 상태로 전달
  };

  const changeLanguage = (lang) => {
    setLanguage(lang);
    setIsPopupOpen(false);
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen); // 클릭 시 팝업 열기/닫기 상태 토글
  };

  const getTitle = () => {
    switch (language) {
      case 'es':
        return '¿Realmente soy saludable?';
      case 'ko':
        return '나는 과연 건강할까?';
      default:
        return 'Am I really healthy?';
    }
  };

  const getEmailPlaceholder = () => {
    switch (language) {
      case 'es':
        return 'Ingrese su correo electrónico';
      case 'ko':
        return '이메일을 입력하세요';
      default:
        return 'Enter your Email';
    }
  };

  const getStartButtonText = () => {
    switch (language) {
      case 'es':
        return 'Iniciar prueba';
      case 'ko':
        return '테스트 시작';
      default:
        return 'Test Start';
    }
  };

  const getEmailErrorMessage = () => {
    switch (language) {
      case 'es':
        return 'Correo electrónico es obligatorio';
      case 'ko':
        return '이메일을 입력하세요';
      default:
        return 'Email is required';
    }
  };

  return (
    <div className="HH-main-body">
      <div className="HH-main-container">
        <div className="HH-language-setting">
        <img
          src="../icons/web_icon/language.png"
          alt="Language img"
          className="HH-Language-image"
          onClick={togglePopup}
        />
        {isPopupOpen && (
        <div className="HH-language-popup">
          <div className="HH-language-btn-group">
            {['en', 'es', 'ko'].map((lang) => (
                <button
                  key={lang}
                  className={`HH-language-btn ${language === lang ? 'selected' : ''}`}
                  onClick={() => changeLanguage(lang)}
                >
                  {lang === 'en' ? 'English' : lang === 'es' ? 'Español' : '한국어'}
                  {language === lang && <span className="HH-checkmark">✔</span>}
                </button>
              ))}
          </div>
        </div>
        )}
        </div>
        <h1 className="HH-main-title">{getTitle()}</h1>
        <div className="HH-bottom-items">
          <input
            type="text"
            className="HH-email-input"
            placeholder={getEmailPlaceholder()}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              if (errorMessage) setErrorMessage(''); // 이메일 입력 시 에러 메시지 초기화
            }}
          />
          {/* {errorMessage && <span className="HH-error-message">{errorMessage}</span>} */}
          <button onClick={startTest} className="HH-main-startButton">
            {getStartButtonText()}
          </button>
        </div>

      </div>

    </div>
  );
}

export default StartPage;
