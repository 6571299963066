import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import SendBird from 'sendbird';
import '../css/HelpSection.css';
import '../css/DoctorProfile.css';
import searchIcon from './icons/search-icon.png';
import logoImage from './icons/Logo.png';
import notificationIcon from './icons/notification.png';
import LogoutModal from './LogoutModal';
import Sidebar from './Sidebar';

const IP = process.env.REACT_APP_HOST;

const sb = new SendBird({ appId: process.env.REACT_APP_APP_ID });


const faqData = [
  {
    question: "I lost my ID and password",
    answer: "If you've lost your ID and password, you can reset them by clicking on the 'Forgot Password' link on the login page. Follow the instructions sent to your registered email to regain access to your account."
  },
  {
    question: "How do I update my profile information?",
    answer: "To update your profile information, go to the 'Edit Profile' section. There, you can modify your personal details, professional information, and profile picture."
  },
  {
    question: "How can I change my bank account information?",
    answer: "You can change your bank account information in the 'Account' section. Look for the 'Bank account registration' field and update it with your new account details."
  },
  {
    question: "What should I do if I encounter a technical issue?",
    answer: "If you encounter a technical issue, please try refreshing the page or logging out and back in. If the problem persists, contact our support team with details of the issue."
  },
  {
    question: "How can I contact customer support?",
    answer: "You can contact our customer support team via email or by phone. Our support hours are Monday to Friday, 9 AM to 5 PM EST."
  }
];

const HelpSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [data, setData] = useState({});
  const [activeMenu, setActiveMenu] = useState('help');
  const email = localStorage.getItem('email');
  const navigate = useNavigate();

  const handleNavigate = (path, menuName) => {
    if (path === -1) {
      navigate(-1);
    } else {
      navigate(path);
      setActiveMenu(menuName);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.post(`/Save/getDrInfo`, { drEmail: email }, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      let fetchedData = response.data.dr;

      // Ensure dr_medical_department is always an array
      if (fetchedData.dr_medical_department) {
        if (typeof fetchedData.dr_medical_department === 'string') {
          fetchedData.dr_medical_department = fetchedData.dr_medical_department.split(',').map(dept => dept.trim());
        } else if (!Array.isArray(fetchedData.dr_medical_department)) {
          console.error('Unexpected type for dr_medical_department:', typeof fetchedData.dr_medical_department);
          fetchedData.dr_medical_department = [];
        }
      } else {
        fetchedData.dr_medical_department = [];
      }

      setData(fetchedData);
      console.log(fetchedData);
      console.log("DoctorProfile getData!!");
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const menuItems = [
    { name: 'editProfile', label: 'Edit Profile', path: '/editProfile' },
    { name: 'account', label: 'Account', path: '/account' },
    { name: 'income', label: 'Income', path: '/pay' },
    { name: 'help', label: 'Help', path: '/help' }
  ];

  const toggleQuestion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const filteredFaqData = faqData.filter((item) =>
    item.question.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleLogoutClick = () => {
    setShowLogoutModal(true);
  };

  const handleLogoutConfirm = async () => {
    try {
      await axios.post('/Login/logout', {}, { withCredentials: true });
      await sb.disconnect();
      window.location.href = '/Login';
    } catch (error) {
      console.error('Error logout:', error);
      alert('Failed to logout');
    } finally {
      setShowLogoutModal(false);
    }
  };

  const handleNotificationClick = () => {
    navigate('/WebChat');
  };

  const onClickBtn = () => {
    navigate('/WebChat');
  };
  
  const goMain = () =>{
    navigate('/WebChat');
    //window.location.reload();
  }

  return (
    <div className="dpp-doctor-profile">
      <div className="wcc-header">
        <div className="wcc-header-left">
        <button onClick={goMain} style={{background: 'none', border: 'none', cursor: 'pointer'}}>
            <img src={logoImage} alt="MEDINOX Logo" className="wcc-logo-image" />
          </button>
        </div>
        <div className="wcc-header-right">
          <button className="wcc-notification-button">
            <img src="./icons/web_icon/notification.png" alt="TTABONG" className="wcc-rate-img" />
          </button>
          <div className="wcc-rate-container">
            <img src="./icons/web_icon/img_rate.png" alt="TTABONG" className="wcc-rate-img" />
            <div className="wcc-rate-text"><span style={{ color: "#01B6CD" }}>{data.dr_rating}</span> / 5</div>
          </div>
          <button className="wcc-drprofile-button">
            <img
              loading="lazy"
              src={data.dr_profile_photo}
              alt="Doctor Profile"
              className="wcc-drprofile-img"
            />
          </button>
        </div>
      </div>
      <div className="dpp-content">
        <Sidebar
          handleLogoutClick={handleLogoutClick} 
          onNavigate={handleNavigate}
          activeMenu={activeMenu}
          menuItems={menuItems}
        />

        <main className="dpp-main-content">
          <div className="help-section">
            <h1>Help Center</h1>
            <p className="search-instruction">Search for your answer here</p>
            <div className="search-bar">
              <input
                type="text"
                placeholder="Tap to search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button type="submit">
                <img src={searchIcon} alt="Search" />
              </button>
            </div>
            <div className="popular-topics">
              <div className='popular-topics_divh2'>Popular Topics</div>
              <div className="faq-list">
                {filteredFaqData.map((item, index) => (
                  <div key={index} className="faq-item">
                    <div
                      className="faq-question"
                      onClick={() => toggleQuestion(index)}
                    >
                      {item.question}
                      <span className={`arrow ${activeIndex === index ? 'up' : 'down'}`}></span>
                    </div>
                    <div className={`faq-answer ${activeIndex === index ? 'active' : ''}`}>
                      {item.answer}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className='Hs_customerService'>
            <div className='Hs_OtherIssues'>Other Issues</div>
            <div className='Hs_CustomerServiceBtn'>Customer Service <span>&gt;</span></div>
          </div>
        </main>
      </div>
      {showLogoutModal && (
        <LogoutModal
          onClose={() => setShowLogoutModal(false)}
          onConfirm={handleLogoutConfirm}
        />
      )}
    </div>
  );
};

export default HelpSection;