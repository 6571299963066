import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAuth, confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import '../css/ProgressBars.css';
import '../css/SetNewPassword.css';
import '../css/public.css';
import '../css/Login.css';
import '../css/Step1_CreateAccount.css';

const SetNewPasswordPage = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [confirmError, setConfirmError] = useState('');
  const [email, setEmail] = useState('');
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [confirmPasswordInvalid, setConfirmPasswordInvalid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const validatePassword = (password) => {
    const re = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()\-_=+\\|[\]{};:\'",.<>/?]).{8,15}$/;
    return re.test(password);
  };

  const validateField = (name, value) => {
    let error = '';

    switch (name) {
      case 'password':
        if (!value) {
          error = 'Password is required';
          setError(error);
        } else if (!validatePassword(value)) {
          error = 'Password must be 8-15 characters and include uppercase, lowercase, number, and special character';
          setError(error);
        } else {
          setError('');
        }
        break;
      case 'confirmPassword':
        if (!value) {
          error = 'Confirm password is required';
          setConfirmError(error);
        } else if (value !== password) {
          error = 'Passwords not match';
          setConfirmError(error);
        } else {
          setConfirmError('');
        }
        break;
      default:
        break;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'password') {
      setPassword(value);
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value);
    }
    validateField(name, value);
  };

  const handleFocus = (field) => {
    setFocusedInput(field);
    if (field === 'password') {
      setError('');
      setPasswordInvalid(false);
    } else if (field === 'confirmPassword') {
      setConfirmError('');
      setConfirmPasswordInvalid(false);
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setFocusedInput(null);
    validateField(name, value);
  };

  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyC8QgdPvMiye3l1dh-cVXn6a1uCLcO3IKw",
      authDomain: "test-93981.firebaseapp.com",
      projectId: "test-93981",
      storageBucket: "test-93981.appspot.com",
      messagingSenderId: "292061460473",
      appId: "1:292061460473:web:696938ca63279b9790af28",
      measurementId: "G-R2RZR78PVJ"
    };

    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);

    const urlParams = new URLSearchParams(location.search);
    const oobCode = urlParams.get('oobCode');
    const emailParam = urlParams.get('email');

    if (emailParam) {
      setEmail(emailParam);
    } else {
      alert('Invalid access. Email information is missing.');
      navigate('/');
    }

    const verifyResetCode = async () => {
      try {
        await verifyPasswordResetCode(auth, oobCode);
      } catch (error) {
        console.error("Error verifying code:", error);
        setError('Invalid or expired link.');
      }
    };

    verifyResetCode();
  }, [location, navigate]);

  const isValidPassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // Reset previous error states
    setError('');
    setConfirmError('');
    setPasswordInvalid(false);
    setConfirmPasswordInvalid(false);

    // Validate both fields
    validateField('password', password);
    validateField('confirmPassword', confirmPassword);

    // If either field is invalid, stop form submission
    if (!password || !confirmPassword || !validatePassword(password) || password !== confirmPassword) {
      setIsLoading(false);
      return;
    }

    try {
      const auth = getAuth();
      const urlParams = new URLSearchParams(location.search);
      const oobCode = urlParams.get('oobCode');
      await confirmPasswordReset(auth, oobCode, password);
      console.log('Password reset successful.');

      const response = await fetch('/change-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        console.log('Password changed successfully on the server.');
        navigate("/CompleteSetPassword");
      } else {
        const errorResponse = await response.json();
        setError(errorResponse.message || 'Failed to change password on the server.');
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      setError('Failed to reset password. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordFocus = () => {
    setError('');
    setPasswordInvalid(false);
  };

  const handleConfirmPasswordFocus = () => {
    setConfirmError('');
    setConfirmPasswordInvalid(false);
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const goLoginPage = () => navigate("/Login");
  const goStartPage = () => navigate("/");

  return (
    <div className="footer_Style_0">
      <div className="fs0_topNaviBar">
        <div className="fs0_logo_container">
          <img src="/icons/web_icon/Logo.png" alt="MediKnocks Logo" className="logo-image" />
        </div>
      </div>
      <div className='rp_contents'>
        <div className='rp_HeaderContainer'>
          <div className="progress-container">
            {[...Array(3)].map((_, index) => (
              <div
                key={index}
                className={`progress-bar ${2 > index ? 'active' : ''}`}
              />
            ))}
          </div>
        </div>
        <div className='rp_allContent'>
          <div className="rp-ForgetPasswordPage_content">
            <h1 className="rp-ForgetPasswordPage_div-8">Set new password</h1>
            <p className="rp-ForgetPasswordPage_div-9">
              Your new password must be different to <br /> previously used passwords.
            </p>
            <div className='rp_content_container'>
              <form onSubmit={handleSubmit}>
                {/* Password Input */}
                <div className={`Step1_input-group Step1_su_login 
                    ${error ? 'error' : ''} 
                    ${focusedInput === 'password' ? 'focused' : ''} 
                    ${password && validatePassword(password) ? 'valid-password' : ''}
                  `}>
                  <img src="/icons/web_icon/img_password.png" className="Step1_lg_img_step1" alt="Password icon" />
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    value={password}
                    onChange={handleInputChange}
                    onFocus={() => handleFocus('password')}
                    onBlur={handleBlur}
                    className='Step1_su_input_password'
                  />
                  <button type="button" className="Step1_toggle-password" onClick={() => setShowPassword(!showPassword)}>
                    <img
                      src={showPassword ? "/icons/web_icon/img_hiddenPassword.png" : "/icons/web_icon/img_showPassword.png"}
                      alt={showPassword ? "Hide password" : "Show password"}
                      className="Step1_password-toggle-icon"
                    />
                  </button>
                </div>
                <div className="Step1_error-container" style={{ textAlign: 'left' }}>
                  {error ? (
                    <div className="Step1_error-text_step1">{error}</div>
                  ) : password && validatePassword(password) ? (
                    <div className="Step1_correct-password">Correct password</div>
                  ) : (
                    <div className="Step1_password-guidance">
                      Password must be 8-15 characters and include uppercase, lowercase, number, and special character
                    </div>
                  )}
                </div>

                {/* Confirm Password Input */}
                <div className={`Step1_input-group Step1_su_login 
                    ${confirmError ? 'error' : ''} 
                    ${focusedInput === 'confirmPassword' ? 'focused' : ''} 
                    ${confirmPassword && confirmPassword === password ? 'valid-password' : ''}
                  `}>
                  <img src="/icons/web_icon/img_password.png" className="Step1_lg_img_step1" alt="Password icon" />
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    placeholder="Confirm password"
                    value={confirmPassword}
                    onChange={handleInputChange}
                    onFocus={() => handleFocus('confirmPassword')}
                    onBlur={handleBlur}
                    className='Step1_su_input_password'
                  />
                  <button type="button" className="Step1_toggle-password" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                    <img
                      src={showConfirmPassword ? "/icons/web_icon/img_hiddenPassword.png" : "/icons/web_icon/img_showPassword.png"}
                      alt={showConfirmPassword ? "Hide password" : "Show password"}
                      className="Step1_password-toggle-icon"
                    />
                  </button>
                </div>
                <div className="Step1_error-container" style={{ textAlign: 'left' }}>
                  {confirmError ? (
                    <div className="Step1_error-text_step1">{confirmError}</div>
                  ) : confirmPassword && confirmPassword === password ? (
                    <div className="Step1_correct-password">Passwords match</div>
                  ) : null}
                </div>

                <input type="hidden" name="email" value={email} />
                <button
                  type="submit"
                  className={`rp-ForgetPasswordPage_div-13 ${isLoading ? 'snp-loading-button' : ''}`}
                  disabled={isLoading}
                >
                  <span className={`snp-button-text ${isLoading ? 'snp-loading' : ''}`}>
                    Reset password
                  </span>
                  {isLoading && <div className="snp-loading-spinner"></div>}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetNewPasswordPage;