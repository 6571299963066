import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { initializeApp, getApps } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import '../css/ResetPassword.css'
import '../css/public.css'
import ProgressBars from './ProgressBars';

const ForgetPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [validationMessage, setValidationMessage] = useState('');
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [emailMessage, setEmailMessage] = useState('');
  const [isEmailNotRegistered, setIsEmailNotRegistered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setIsEmailNotRegistered(false);
    
    if (!value) {
      setIsEmailValid(false);
      setEmailMessage('');
      setValidationMessage('');
    } else {
      const isValid = validateEmail(value);
      setIsEmailValid(isValid);
      if (isValid) {
        setEmailMessage('');
        setValidationMessage('');
      } else {
        setEmailMessage('');
        setValidationMessage('Please enter your email address in format: yourname@example.com');
      }
    }
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9]{2,}@[a-zA-Z0-9]{3,}\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleFocus = (field) => {
    setFocusedInput(field);
    setValidationMessage('');  // 에러 메시지 초기화
    setIsEmailNotRegistered(false);
  };

  const handleBlur = () => {
    setFocusedInput(null);
    if (!email) {
      setValidationMessage('Email Address is required');
    } else if (!validateEmail(email)) {
      setValidationMessage('Please enter your email address in format: yourname@example.com');
    }
  };

  const handleClearInput = () => {
    setEmail('');
    setIsEmailSent(false);
    setIsEmailValid(false);
    setEmailMessage('');
    setValidationMessage('');
    setIsEmailNotRegistered(false);
  };

  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyC8QgdPvMiye3l1dh-cVXn6a1uCLcO3IKw",
      authDomain: "test-93981.firebaseapp.com",
      projectId: "test-93981",
      storageBucket: "test-93981.appspot.com",
      messagingSenderId: "292061460473",
      appId: "1:292061460473:web:696938ca63279b9790af28",
      measurementId: "G-R2RZR78PVJ"
    };

    let app;
    if (!getApps().length) {
      app = initializeApp(firebaseConfig);
    } else {
      app = getApps()[0];
    }

    const analytics = getAnalytics(app);
    const auth = getAuth(app);
  }, []);

  const isValidEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,}$/;
    return regex.test(email);
  };

  const handleSendVerification = async () => {
    setIsLoading(true);
    const trimmedEmail = email.trim().toLowerCase();

    // 이메일이 비어있는 경우
    if (!trimmedEmail) {
      setValidationMessage('Email Address is required');
      setIsLoading(false);
      return;
    }

    // 이메일 형식이 잘못된 경우
    if (!validateEmail(trimmedEmail)) {
      setValidationMessage('Please enter your email address in format: yourname@example.com');
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post('/firebase-admin/check-email', { email: trimmedEmail });
      const data = response.data;

      if (data.exists) {
        const actionCodeSettings = {
          url: `${window.location.origin}/SetNewPassword?email=${encodeURIComponent(trimmedEmail)}`,
          handleCodeInApp: true
        };

        const auth = getAuth();
        await sendSignInLinkToEmail(auth, trimmedEmail, actionCodeSettings);
        
        window.localStorage.setItem('emailForSignIn', trimmedEmail);
        setEmailMessage('Email verification link sent. Please check your inbox.');
        setIsEmailSent(true);
        setValidationMessage('');
        setIsEmailNotRegistered(false);

        navigate(`/resetpasswordcomplete?email=${encodeURIComponent(trimmedEmail)}`);
        
      } else {
        setValidationMessage('Email address is not registered');
        setEmailMessage('');
        setIsEmailNotRegistered(true);
      }
    } catch (error) {
      console.error('Error checking email:', error);
      setValidationMessage('An error occurred while checking the email address.');
      setEmailMessage('');
    } finally {
      setIsLoading(false);  // 로딩 종료
    }
  };

  return (
    <div className="rp-ForgetPasswordPage_div">
      <div className="fs0_topNaviBar">
        <div className="fs0_logo_container">
          <img src="/icons/web_icon/Logo.png" alt="MediKnocks Logo" className="logo-image" />
        </div>
      </div>
      <div className='rp_contents'>
        <div className='rp_HeaderContainer'>
          <ProgressBars currentStep={1} totalSteps={3} />
        </div>
        <div className='rp_allContent'>
          <div className="rp-ForgetPasswordPage_content">
            <h1 className="rp-ForgetPasswordPage_div-8">Forgot password?</h1>
            <p className="rp-ForgetPasswordPage_div-9">
              Enter the email address associated with your account and we'll send you a link to reset your password.
            </p>
            <div className='rp_content_container'>
            <div className={`rp_input-group rp_su_login_email 
                ${(validationMessage || isEmailNotRegistered) ? 'error' : 
                  (isEmailValid && email && !isEmailNotRegistered) ? 'valid-email' : ''} 
                ${focusedInput === 'email' ? 'focused' : ''} 
                ${isEmailSent ? 'verification-sent' : ''}`}>
                <img src="/icons/web_icon/img_email.png" className="rp_lg_img_rp" alt="Email icon" />
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={handleInputChange}
                  onFocus={() => handleFocus('email')}
                  onBlur={handleBlur}
                  required
                  className='rp_su_input_email'
                  disabled={isEmailSent}
                />
                {email && (
                  <button type="button" className="rp_btnClear" onClick={handleClearInput}>
                    ×
                  </button>
                )}
              </div>
              <div className="rp_error-container">
                {validationMessage && (
                  <div className="rp_error-text_rp">{validationMessage}</div>
                )}
                {!validationMessage && emailMessage && (
                  <div className={
                    emailMessage === 'Please use the button to send a verification link to your email inbox'
                      ? "rp_password-guidance"
                      : "rp_verification-sent"
                  }>
                    {emailMessage}
                  </div>
                )}
              </div>
              <button 
                className={`rp-ForgetPasswordPage_div-13 ${isLoading ? 'rp-loading-button' : ''}`}
                type="button" 
                onClick={handleSendVerification}
                disabled={isLoading}
              >
                <span className={`rp-button-text ${isLoading ? 'rp-loading' : ''}`}>
                  Next
                </span>
                {isLoading && <div className="rp-loading-spinner"></div>}
              </button>
              <div className="rp-ForgetPasswordPage_div-14">
                <img src="/icons/web_icon/img_rpBackArrow.png" alt="" className='rp_lg_img_rp_back'/>
                <a href="/EditProfile" className="rp-a">Back to Profile</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPasswordPage;