import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/PaySection.css';
import logoImage from './icons/Logo.png';
import notificationIcon from './icons/notification.png';
import LogoutModal from './LogoutModal';
import downloadIcon from './icons/Download.png';
import jsPDF from 'jspdf';
import arrow3 from './icons/arrow3.png';
import arrow from './icons/arrow.png';
import arrow2 from './icons/arrow2.png';
import Sidebar from './Sidebar';
import { ChevronLeft, ChevronDown } from 'lucide-react';

const IP = process.env.REACT_APP_HOST;

const PaySection = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [weeklyIncome, setWeeklyIncome] = useState([]);
  const [dailyIncome, setDailyIncome] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [expandedDay, setExpandedDay] = useState(null);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [expandedWeek, setExpandedWeek] = useState(null);
  const [activeMenu, setActiveMenu] = useState('income');
  const [data, setData] = useState({});
  const email = localStorage.getItem('email');
  const navigate = useNavigate();
  const [currentWeekIndex, setCurrentWeekIndex] = useState(0);

  const formatMonth = (date) => {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return months[new Date(date).getMonth()];
  };

  const menuItems = [
    { name: 'editProfile', label: 'Edit Profile', path: '/editProfile' },
    { name: 'account', label: 'Account', path: '/account' },
    { name: 'income', label: 'Income', path: '/pay' },
    { name: 'help', label: 'Help', path: '/help' }
  ];

  const handleWeekNavigation = (direction) => {
    if (direction === 'prev' && currentWeekIndex < weeklyIncome.length - 1) {
      setCurrentWeekIndex(prevIndex => prevIndex + 1);
      const prevWeek = weeklyIncome[currentWeekIndex + 1];
      setSelectedWeek(prevWeek);
      fetchDailyIncome(prevWeek.startDate, prevWeek.endDate);
    } else if (direction === 'next' && currentWeekIndex > 0) {
      setCurrentWeekIndex(prevIndex => prevIndex - 1);
      const nextWeek = weeklyIncome[currentWeekIndex - 1];
      setSelectedWeek(nextWeek);
      fetchDailyIncome(nextWeek.startDate, nextWeek.endDate);
    }
  };

  const handleWeekClick = (week) => {
    if (selectedWeek && selectedWeek.startDate === week.startDate) {
      setSelectedWeek(null);
      setExpandedWeek(null);
      setCurrentWeekIndex(0);
    } else {
      const index = weeklyIncome.findIndex(w => w.startDate === week.startDate);
      setCurrentWeekIndex(index);
      setSelectedWeek(week);
      fetchDailyIncome(week.startDate, week.endDate);
      setExpandedWeek(week.startDate);
    }
  };

  const handleNavigate = (path) => {
    if (path === -1) {
      navigate(-1);
    } else {
      navigate(path);
      // URL 경로에 따라 activeMenu 설정
      const menuName = path.split('/')[1] || 'editProfile';
      setActiveMenu(menuName);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.post(`/Save/getDrInfo`, { drEmail: email }, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      let fetchedData = response.data.dr;

      // Ensure dr_medical_department is always an array
      if (fetchedData.dr_medical_department) {
        if (typeof fetchedData.dr_medical_department === 'string') {
          fetchedData.dr_medical_department = fetchedData.dr_medical_department.split(',').map(dept => dept.trim());
        } else if (!Array.isArray(fetchedData.dr_medical_department)) {
          console.error('Unexpected type for dr_medical_department:', typeof fetchedData.dr_medical_department);
          fetchedData.dr_medical_department = [];
        }
      } else {
        fetchedData.dr_medical_department = [];
      }

      setData(fetchedData);
      console.log(fetchedData);
      console.log("DoctorProfile getData!!");
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    console.log('Current year:', year);
    console.log('Stored email:', localStorage.getItem('email'));
    fetchWeeklyIncome();
  }, [year]);

  const fetchWeeklyIncome = async () => {
    try {
      const response = await axios.post(`/DrIncome/weekly`,
        { drEmail: localStorage.getItem('email') },
        { withCredentials: true }
      );

      const weeklyData = Array.isArray(response.data) ? response.data : [];
      const sortedData = weeklyData.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));

      setWeeklyIncome(sortedData);
      const total = sortedData.reduce((sum, week) => sum + week.totalAmount, 0);
      setTotalAmount(total);
    } catch (error) {
      console.error('Error fetching weekly income:', error);
    }
  };

  const fetchDailyIncome = async (startDate, endDate) => {
    try {
      const response = await axios.post(`/DrIncome/daily`, {
        drEmail: localStorage.getItem('email'),
        startDate,
        endDate
      }, {
        withCredentials: true
      });

      const filledData = fillMissingDates(response.data, new Date(startDate), new Date(endDate));
      setDailyIncome(filledData);
    } catch (error) {
      console.error('Error fetching daily income:', error);
    }
  };

  const fillMissingDates = (data, startDate, endDate) => {
    const filledData = [];
    const currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const existingData = data.find(item => new Date(item._id).toDateString() === currentDate.toDateString());
      if (existingData) {
        filledData.push(existingData);
      } else {
        filledData.push({
          _id: currentDate.toISOString(),
          amount: 0,
          cases: 0,
          treatments: []
        });
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return filledData;
  };

  const formatCurrency = (amount) => `$ ${Number(amount).toFixed(2)}`;
  const formatDate = (date) => {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const d = new Date(date);
    return `${months[d.getMonth()]} ${d.getDate()}`;
  };
  /*
    const handleWeekClick = (week) => {
      setSelectedWeek(week);
      fetchDailyIncome(week.startDate, week.endDate);
    };
  
    const handleDayClick = (date) => {
      setExpandedDay(expandedDay === date ? null : date);
    };
  */


  const handleDayClick = (date) => {
    setExpandedDay(expandedDay === date ? null : date);
  };
  const handleBackToWeekly = () => {
    setSelectedWeek(null);
    setExpandedDay(null);
  };

  const handleLogoutClick = () => {
    setShowLogoutModal(true);
  };

  const handleLogoutConfirm = async () => {
    try {
      await axios.post(`/Login/logout`, {}, { withCredentials: true });
      navigate('/Login');
    } catch (error) {
      console.error('Error logging out:', error);
      alert('Failed to logout');
    } finally {
      setShowLogoutModal(false);
    }
  };

  // PDF 문서 생성
  const handleDownloadStatement = () => {
    try {
      // PDF 문서 생성
      const doc = new jsPDF();

      // 제목 추가
      doc.setFontSize(18);
      doc.text('Payment Statement', 105, 20, null, null, 'center');

      // 연도 추가
      doc.setFontSize(14);
      doc.text(`Year: ${year}`, 20, 40);

      // 총액 추가
      doc.setFontSize(16);
      doc.text(`Total Amount: ${formatCurrency(totalAmount)}`, 20, 60);

      // 날짜 추가
      const today = new Date();
      doc.setFontSize(12);
      doc.text(`Generated on: ${today.toLocaleDateString()}`, 20, 80);

      // PDF 저장
      doc.save(`payment_statement_${year}.pdf`);

      console.log('Statement downloaded successfully');
    } catch (error) {
      console.error('Error creating statement:', error);
      alert('Failed to create statement. Please try again.');
    }
  };
  const goMain = () =>{
    navigate('/WebChat');
    //window.location.reload();
  }

  return (
    <div className="dpp-doctor-profile">
      <div className="wcc-header">
        <div className="wcc-header-left">
        <button onClick={goMain} style={{background: 'none', border: 'none', cursor: 'pointer'}}>
            <img src={logoImage} alt="MEDINOX Logo" className="wcc-logo-image" />
          </button>
        </div>
        <div className="wcc-header-right">
          <button className="wcc-notification-button">
            <img src="./icons/web_icon/notification.png" alt="TTABONG" className="wcc-rate-img" />
          </button>
          <div className="wcc-rate-container">
            <img src="./icons/web_icon/img_rate.png" alt="TTABONG" className="wcc-rate-img" />
            <div className="wcc-rate-text"><span style={{ color: "#01B6CD" }}>{data.dr_rating}</span> / 5</div>
          </div>
          <button className="wcc-drprofile-button">
            <img
              loading="lazy"
              src={data.dr_profile_photo}
              alt="Doctor Profile"
              className="wcc-drprofile-img"
            />
          </button>
        </div>
      </div>
      <div className="dpp-content">
        <Sidebar
          handleLogoutClick={handleLogoutClick}
          onNavigate={handleNavigate}
          activeMenu={activeMenu}
        />
        <main className="dpp-main-content">
          <div className="pay-section">
            <div className='ps_headerContainer'>
              <img src="./icons/web_icon/img_psHeader.png" alt="" className='ps_img' />
              <div className='ps_title'>Income Details</div>
            </div>
            {!selectedWeek && (
              <>
                {/*<div className="pay-header">
                  <select value={year} onChange={(e) => setYear(Number(e.target.value))}>
                    {[...Array(5)].map((_, i) => (
                      <option key={i} value={new Date().getFullYear() - i}>
                        {new Date().getFullYear() - i}
                      </option>
                    ))}
                  </select>
                </div>*/}
                <div className="total-amount">
                  <div className='ps_total'>Total</div>
                  <div className='ps_ta'>{formatCurrency(totalAmount)}</div>
                </div>
              </>
            )}
            {!selectedWeek ? (
              <>
                <div className="table-container">
                  <table className="income-table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Income</th>
                        <th>Statement</th>
                        <th>Cases</th>
                      </tr>
                    </thead>
                    <tbody>
                      {weeklyIncome.map((week, index) => (
                        <tr
                          key={week.startDate}
                          onClick={() => handleWeekClick(week)}
                        >
                          <td>{formatDate(week.startDate)} - {formatDate(week.endDate)}</td>
                          <td>{formatCurrency(week.totalAmount)}</td>
                          <td className={week.paymentStatus === 'completed' ? 'completed' : 'pending'}>
                            {week.paymentStatus === 'completed' ? 'Payment Completed' : 'unpaid'}
                          </td>
                          <td>
                            <div className="arrow-container">
                              <span>{week.totalCases} cases</span>
                              <img src={arrow} alt="More details" className="arrow-icon" />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <div className="weekly-view">
              {/* 연도 헤더 */}
              <div className="year-header">
                <button className="nav-button" onClick={handleBackToWeekly}>
                  <img src={arrow2} alt="Previous" />
                </button>
                <span style={{ fontSize: '20px'}}>2024</span>
                <div style={{width: '75px'}}></div>
              </div>
          
              {/* 주간 네비게이션 */}
              <div className="week-navigation2">
                <button 
                  className="nav-button" 
                  onClick={() => handleWeekNavigation('prev')}
                  disabled={currentWeekIndex >= weeklyIncome.length - 1}
                >
                  <img src={arrow3} alt="Previous" style={{ transform: 'rotate(180deg)', opacity: currentWeekIndex >= weeklyIncome.length - 1 ? 0 : 1 }} />
                </button>
                <span style={{ fontSize: '20px'}}>{formatDate(selectedWeek.startDate)} ~ {formatDate(selectedWeek.endDate)}</span>
                <button 
                  className="nav-button" 
                  onClick={() => handleWeekNavigation('next')}
                  disabled={currentWeekIndex <= 0}
                >
                  <img 
                    src={arrow3} 
                    alt="Next" 
                    style={{ opacity: currentWeekIndex <= 0 ? 0 : 1 }}
                  />
                </button>
                <div></div>
              </div>
          
              {/* 총액 섹션 */}
              <div className="total-label2">Total</div>
              <div className="total-section">
                <div className="total-amount">${selectedWeek.totalAmount.toFixed(2)}</div>
                <span className="income-label">Income</span>
                <div className="total-info">
                  <span className="cases-count">{selectedWeek.totalCases} cases</span>
                </div>
              </div>
          
              {/* 일별 리스트 */}
              <div className="daily-list">
                {dailyIncome.map((day) => (
                  <div key={day._id} className="daily-item">
                    <div 
                      className={`daily-header ${expandedDay === day._id ? 'expanded' : ''}`}
                      onClick={() => handleDayClick(day._id)}
                    >
                      <div className="day-info">
                        <span className="arrow-icon">▶</span>
                        <span className="day-date">
                          {formatMonth(day._id)} {new Date(day._id).getDate()}
                        </span>
                      </div>
                      <div className="day-amount">${day.amount.toFixed(2)}</div>
                      <div className="day-cases">{day.cases} cases</div>
                    </div>
                    
                    {expandedDay === day._id && day.treatments.length > 0 && (
                      <div className="treatments-table" style={{padding: '0'}}>
                        <table>
                          <thead>
                            <tr>
                              <th style={{borderBottom: 'solid 1px #839699'}}>Consultation Time</th>
                              <th style={{borderBottom: 'solid 1px #839699'}}>Income</th>
                              <th style={{borderBottom: 'solid 1px #839699'}}>Patient Information</th>
                            </tr>
                          </thead>
                          <tbody>
                            {day.treatments.map((treatment, index) => (
                              <tr key={index}>
                                <td style={{    color: '#6B7677'}}>{treatment.startTime} ~ {treatment.endTime}</td>
                                <td style={{    color: '#2F3637'}}>${treatment.amount.toFixed(2)}</td>
                                <td style={{    color: '#6B7677'}}>{treatment.patientName} ({treatment.patientAge}, {treatment.patientGender})</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            )}
            {/*{!selectedWeek && (            )}*/}
              <div className="download-button-container">
                <button onClick={handleDownloadStatement} className="download-button">
                  <span>Payment Statement</span>
                  <img src={downloadIcon} alt="Download" className="download-icon" />
                </button>
              </div>

          </div>
        </main>
      </div>
      {showLogoutModal && (
        <LogoutModal
          onClose={() => setShowLogoutModal(false)}
          onConfirm={handleLogoutConfirm}
        />
      )}
    </div>
  );
};

export default PaySection;