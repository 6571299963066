import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import SendbirdChat from '@sendbird/chat';
import { format } from 'date-fns';
import { GroupChannelModule, GroupChannelHandler } from '@sendbird/chat/groupChannel';
import { timestampToTime, handleEnterPress } from './utils/messageUtils';
import typingGif from '../gifs/typing.gif';
import checkIcon from '../pages/icons/checkIcon.png';
import clipIcon from '../pages/icons/Clip.png';
import axios from 'axios';
import '../css/ChatComponent.css';

const SERVER_URL = process.env.REACT_APP_HOST || 'localhost:3000';

const formatDate = (timestamp) => {
  try {
    const date = new Date(timestamp);
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date');
    }
    return format(date, 'MMMM d, yyyy');
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Invalid date';
  }
};

const formatTime = (timestamp) => {
  try {
    const date = new Date(timestamp);
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date');
    }
    return format(date, 'hh:mm a');
  } catch (error) {
    console.error('Error formatting time:', error);
    return 'Invalid time';
  }
};

const isImageFile = (type) => type && type.startsWith('image/');
const isVideoFile = (type) => type && type.startsWith('video/');
const isPdfFile = (type) => type === 'application/pdf';
const toEmail = (userId) => userId.replace('^', '@');

const MessageGroup = React.memo(({ 
  messages, 
  userId, 
  sb, 
  channel, 
  isDoctor, 
  patientProfileUrl,
  setMessages,
  scrollToBottom
}) => {
  const firstMessage = messages[0];
  const lastMessage = messages[messages.length - 1];
  const messageSenderUserId = firstMessage.sender?.userId ?? 'Unknown';
  const messageSenderNickname = firstMessage.sender?.nickname ?? 'Unknown User';
  const isUnknownUser = messageSenderNickname === 'Unknown User';

  const [isLastMessageRead, setIsLastMessageRead] = useState(false);

  useEffect(() => {
    const checkReadStatus = () => {
      if (channel && lastMessage.sender && lastMessage.sender.userId === userId) {
        const readStatus = channel.getReadStatus();
        const isReadByAll = Object.values(readStatus).every(status => status.readAt >= lastMessage.createdAt);
        setIsLastMessageRead(isReadByAll);
      }
    };

    checkReadStatus();

    const handlerId = `READ_RECEIPT_HANDLER_${lastMessage.messageId}`;

    const channelHandler = new GroupChannelHandler();
    channelHandler.onReadReceiptUpdated = (targetChannel) => {
      if (targetChannel.url === channel.url) {
        checkReadStatus();
      }
    };

    sb.groupChannel.addGroupChannelHandler(handlerId, channelHandler);

    return () => {
      sb.groupChannel.removeGroupChannelHandler(handlerId);
    };
  }, [channel, lastMessage, userId, sb]);

  const getMessageGroupClass = () => {
    if (isDoctor) return "message-group doctor";
    if (isUnknownUser) return "message-group patient-admin";
    return "message-group patient";
  };

  return (
    <div className={getMessageGroupClass()}>
      {!isDoctor && !isUnknownUser && <div className="avatar" style={{ backgroundImage: `url(${patientProfileUrl})` }}></div>}
      <div className="message-content-wrapper">
        {!isDoctor && !isUnknownUser && <div className="sender">{messageSenderNickname}</div>}
        {messages.map((message, index) => (
          <MessageItem
            key={message.messageId}
            message={message}
            userId={userId}
            isDoctor={isDoctor}
            showTimeAndReceipt={index === messages.length - 1}
            isLastMessageRead={isLastMessageRead}
            channel={channel}  // channel prop 추가
            setMessages={setMessages}  // setMessages도 전달
            scrollToBottom={scrollToBottom}
          />
        ))}
      </div>
    </div>
  );
});

const MessageItem = React.memo(({ 
  message, 
  userId, 
  isDoctor, 
  showTimeAndReceipt, 
  isLastMessageRead,
  channel,
  setMessages,
  scrollToBottom
}) => {
  const [translatedMessage, setTranslatedMessage] = useState(message.message);
  const messageSenderNickname = message.sender?.nickname ?? 'Unknown User';
  const isAdmin = messageSenderNickname === 'Unknown User';
  const isImageMessage = isImageFile(message.type);
  const isVideoMessage = isVideoFile(message.type);
  const isPdfMessage = isPdfFile(message.type);
  const fileUrl = message.url || message.plainUrl || (message.messageParams && message.messageParams.file && URL.createObjectURL(message.messageParams.file));
  const [isMediaLoaded, setIsMediaLoaded] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 3;  // 최대 재시도 횟수

  useEffect(() => {
    if (isMediaLoaded && (isImageMessage || isVideoMessage)) {
      scrollToBottom();
    }
  }, [isMediaLoaded, isImageMessage, isVideoMessage, scrollToBottom]);

  useEffect(() => {
    setTranslatedMessage(message.translatedMessage || message.message);
  }, [message]);

  useEffect(() => {
    return () => {
      if (fileUrl && fileUrl.startsWith('blob:')) {
        URL.revokeObjectURL(fileUrl);
      }
    };
  }, [fileUrl]);

  const onFileError = useCallback(async (error) => {
    console.error("File load error - reloading messages");
    
    if (retryCount < MAX_RETRIES) {
      try {
        // 메시지 다시 로드
        const messageList = await channel.getMessagesByTimestamp(Date.now(), { 
          prevResultSize: 100,
          nextResultSize: 0 
        });
        
        // 지연 시간을 둔 후 상태 업데이트
        setTimeout(() => {
          setMessages(messageList);
          setRetryCount(prev => prev + 1);
        }, 1000 * (retryCount + 1));  // 재시도마다 지연 시간 증가
        
      } catch (error) {
        console.error('Error reloading messages:', error);
      }
    }
  }, [channel, setMessages, retryCount]);

  useEffect(() => {
    // 컴포넌트 마운트시 재시도 카운트 초기화
    setRetryCount(0);
  }, [message.plainUrl]);  // URL이 변경될 때마다 초기화

  const renderMessageContent = () => {
    if (isImageMessage) {
      return (
        <img
          src={fileUrl}
          alt="Shared image"
          onError={onFileError}
          onLoad={() => setIsMediaLoaded(true)}
        />
      );
    } else if (isVideoMessage) {
      return (
        <video
          controls
          onError={onFileError}
          key={`${message.plainUrl}-${retryCount}`} 
          onLoadedData={() => setIsMediaLoaded(true)}
        >
          <source src={fileUrl} type={message.type} />
          Your browser does not support the video tag.
        </video>
      );
    } else if (isPdfMessage) {
      return (
        <div className="message-pdf">
          <a
            href={fileUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="pdf-link"
            onClick={(e) => {
              if (!message.url) {
                e.preventDefault();
                onFileError();  // 에러 발생시 메시지 리로드
              }
              scrollToBottom();
            }}
          >
            {message.name || "View PDF"}
          </a>
        </div>
      );
    } else {
      return <div className="message-text">{translatedMessage}</div>;
    }
  };

  if (isAdmin) {
    return (
      <div className="admin-message-content">
        <div className="admin-message-container">{translatedMessage}</div>
      </div>
    );
  }

  const getMessageContentClass = () => {
    if (isImageMessage) return "message-content-image";
    if (isVideoMessage) return "message-content-video";
    return "message-content";
  };

  return (
    <div className={`message-item ${isDoctor ? 'doctor' : 'patient'}`}>
      {isDoctor && showTimeAndReceipt && (
        <div className="message-status">
          <div className="message-time">
            {formatTime(message.createdAt)}
          </div>
          {isLastMessageRead && <img src={checkIcon} alt="Read" className="read-check-icon" />}
        </div>
      )}
      <div className={getMessageContentClass()}>
        {renderMessageContent()}
      </div>
      {!isDoctor && showTimeAndReceipt && (
        <div className="message-time">
          {formatTime(message.createdAt)}
        </div>
      )}
    </div>
  );
});

const ChatComponent = ({ appId, userId, channelUrl, isFrozen }) => {
  const [messages, setMessages] = useState([]);
  const [channel, setChannel] = useState(null);
  const [inputMessage, setInputMessage] = useState('');
  const [typingMembers, setTypingMembers] = useState([]);
  const [patientProfileUrl, setPatientProfileUrl] = useState('');
  const sbRef = useRef(null);
  const messageListRef = useRef(null);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);
  const [file, setFile] = useState(null);

  const scrollToBottom = useCallback(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  const onMessageInputChange = (e) => {
    setInputMessage(e.target.value);
    if (channel) {
      channel.startTyping();
    }
  };

  const sendMessage = async () => {
    if (inputMessage.trim() && channel) {
      const userMessageParams = {};
      userMessageParams.message = inputMessage;
      channel.sendUserMessage(userMessageParams)
        .onSucceeded((message) => {
          setMessages((prevMessages) => [...prevMessages, message]);
          setInputMessage('');
          scrollToBottom();
        })
        .onFailed((error) => {
          console.error('Error sending message:', error);
        });
    }
  };

  const onFileInputChange = async (e) => {
    if (e.currentTarget.files && e.currentTarget.files.length > 0) {
      const file = e.currentTarget.files[0];
      const fileMessageParams = {};
      fileMessageParams.file = file;

      if (file.type.startsWith('video/')) {
        fileMessageParams.thumbnailSizes = [{ maxWidth: 100, maxHeight: 100 }];
      }

      channel.sendFileMessage(fileMessageParams)
        .onSucceeded((message) => {
          const localUrl = URL.createObjectURL(file);
          const messageWithLocalUrl = {
            ...message,
            url: localUrl,
            type: file.type
          };
          setMessages((prevMessages) => [...prevMessages, messageWithLocalUrl]);
          setFile(null);
          //scrollToBottom();
          setTimeout(scrollToBottom, 100);
        })
        .onFailed((error) => {
          console.error('Error sending file message:', error);
        });
    }
  };

  useEffect(() => {
    const initializeSendbird = async () => {
      try {
        const sendbirdInstance = SendbirdChat.init({
          appId: appId,
          modules: [new GroupChannelModule()]
        });

        await sendbirdInstance.connect(userId);
        sbRef.current = sendbirdInstance;

        const channel = await sendbirdInstance.groupChannel.getChannel(channelUrl);
        setChannel(channel);

        await channel.markAsRead();

        const messageList = await channel.getMessagesByTimestamp(Date.now(), { prevResultSize: 100, nextResultSize: 0 });
        const translatedMessages = await Promise.all(messageList.map(async (message) => {
          try {
            const translatedMsg = await message.translations['en'];
            return { ...message, translatedMessage: translatedMsg };
          } catch (error) {
            console.error('Translation error:', error);
            return message;
          }
        }));
        setMessages(translatedMessages);
        setupChannelHandler(sendbirdInstance);
        try {
          const response = await axios.post('/api/getUserCallInfo', { channelUrl });
          const profileUrlSuffix = response.data.chatPrgrs[0].iconImage; // API에서 반환된 접미사
          const fullProfileUrl = `/icons/${profileUrlSuffix}_Active.png`;
          console.log(response.data);
          console.log(response.data.chatPrgrs[0]);
          console.log(response.data.chatPrgrs[0].iconImage);
          console.log(fullProfileUrl);
          setPatientProfileUrl(fullProfileUrl);
        } catch (error) {
          console.error('Error fetching patient profile:', error);
        }
      } catch (error) {
        console.error('Error initializing Sendbird:', error);
      }
    };
    initializeSendbird();

    return () => {
      if (sbRef.current) {
        sbRef.current.groupChannel.removeGroupChannelHandler('UNIQUE_HANDLER_ID');
        sbRef.current.disconnect();
      }
    };
  }, [appId, userId, channelUrl]);

  const setupChannelHandler = (sendbirdInstance) => {
    const handlerId = 'UNIQUE_HANDLER_ID';

    const channelHandler = new GroupChannelHandler();

    channelHandler.onMessageReceived = async (channel, message) => {
      try {
        const translatedMsg = message.translations['en'];
        const translatedMessage = {
          ...message,
          translatedMessage: translatedMsg
        };
        setMessages((prevMessages) => [...prevMessages, translatedMessage]);
        scrollToBottom();
      } catch (error) {
        console.error('Translation error:', error);
        setMessages((prevMessages) => [...prevMessages, {...message, translatedMessage: message.message }]);
        scrollToBottom();
      }
    };

    channelHandler.onTypingStatusUpdated = (channel) => {
      setTypingMembers(channel.getTypingUsers());
    };

    channelHandler.onReadReceiptUpdated = (channel) => {
      setMessages((prevMessages) => [...prevMessages]);
    };

    sendbirdInstance.groupChannel.addGroupChannelHandler(handlerId, channelHandler);
  };

  const renderMessages = useCallback(() => {
    let lastDate = null;
    let messageGroups = [];
    let currentGroup = [];
    let lastSenderId = null;
    let lastMessageTime = null;

    messages.forEach((message, index) => {
      const currentDate = formatDate(message.createdAt);
      const currentSenderId = message.sender?.userId;
      const currentMessageTime = formatTime(message.createdAt);
      const isDoctor = currentSenderId === userId;

      if (currentDate !== lastDate) {
        if (currentGroup.length > 0) {
          messageGroups.push(currentGroup);
          currentGroup = [];
        }
        messageGroups.push({ type: 'dateSeparator', date: currentDate });
        lastDate = currentDate;
        lastSenderId = null;
        lastMessageTime = null;
      }

      if (currentGroup.length === 0 || (currentSenderId === lastSenderId && currentMessageTime === lastMessageTime)) {
        currentGroup.push(message);
      } else {
        messageGroups.push(currentGroup);
        currentGroup = [message];
      }

      lastSenderId = currentSenderId;
      lastMessageTime = currentMessageTime;

      if (index === messages.length - 1) {
        messageGroups.push(currentGroup);
      }
    });

    return messageGroups.map((group, groupIndex) => {
      if (group.type === 'dateSeparator') {
        return (
          <div key={`date-${groupIndex}`} className="date-separator">
            {group.date}
          </div>
        );
      }
      const isDoctor = group[0].sender?.userId === userId;
      return (
        <MessageGroup
          key={`group-${groupIndex}`}
          messages={group}
          userId={userId}
          sb={sbRef.current}
          channel={channel}
          isDoctor={isDoctor}
          patientProfileUrl= {patientProfileUrl}
          setMessages={setMessages}
          scrollToBottom={scrollToBottom}
        />
      );
    });
  }, [messages, userId, channel, patientProfileUrl]);

  const handleScroll = useCallback(() => {
    if (messageListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messageListRef.current;
      const isAtBottom = scrollHeight - scrollTop - clientHeight < 1;
      setIsScrolledToBottom(isAtBottom);
    }
  }, []);

  useEffect(() => {
    if (messageListRef.current && isScrolledToBottom) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages, isScrolledToBottom]);

  useEffect(() => {
    const messageList = messageListRef.current;
    if (messageList) {
      messageList.addEventListener('scroll', handleScroll);
      return () => messageList.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  return (
    <div className="chat-container">
      <div className="message-list" ref={messageListRef} onScroll={handleScroll}>
        {renderMessages()}
      </div>
      {typingMembers.length > 0 && (
        <div className="typing-indicator">
          <img src={typingGif} alt="typing" className="typing-gif" />
        </div>
      )}
      <MessageInput
        value={inputMessage}
        onChange={onMessageInputChange}
        sendMessage={sendMessage}
        onFileInputChange={onFileInputChange}
        isFrozen={isFrozen}
      />
    </div>
  );
};

const MessageInput = ({ value, onChange, sendMessage, onFileInputChange, isFrozen }) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey && !isFrozen) {
      event.preventDefault();
      sendMessage();
    }
  };

  return (
    <div className={`message-input ${isFrozen ? 'frozen' : ''}`}>
      <input
        type="text"
        className="chat-input"
        value={value}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        placeholder={isFrozen ? "This chat is inactive" : "Enter message"}
        disabled={isFrozen}
      />
      <div className="message-input-buttons">
        <label className={`file-upload-label ${isFrozen ? 'disabled' : ''}`} htmlFor="upload">
          <img src={clipIcon} alt="Attach file" className="clip-icon" />
        </label>
        <input
          id="upload"
          className="file-upload-button"
          type="file"
          accept="image/*,video/*"
          onChange={onFileInputChange}
          disabled={isFrozen}
        />
      </div>
    </div>
  );
};

MessageInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
  onFileInputChange: PropTypes.func.isRequired,
  isFrozen: PropTypes.bool.isRequired,
};

ChatComponent.propTypes = {
  appId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  channelUrl: PropTypes.string.isRequired,
  isFrozen: PropTypes.bool.isRequired,
};

export default ChatComponent;