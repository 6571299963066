import React from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '../css/PatientHistoryPDF.css';

function PatientHistoryPDF({ prescriptionUrl, onClose }) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div className="patient-history-pdf-modal">
      <div className="patient-history-pdf-content">
        <div className="pdf-viewer-container">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            <Viewer
              fileUrl={prescriptionUrl}
              plugins={[defaultLayoutPluginInstance]}
              defaultScale={1.2}
            />
          </Worker>
        </div>
        <div className="Patient_footer">
          <button className="PatientHistory_back-button" onClick={onClose}>Back</button>
        </div>
      </div>
    </div>
  );
}

export default PatientHistoryPDF;