import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import './App.css';

import SendbirdProviderWrapper from './SendbirdProviderWrapper';
import DoctorProfile from './pages/DoctorProfile';
import axios from 'axios';
// Page imports
import Start from './pages/StartPage.jsx';
import LoginPage from './pages/LoginPage.jsx'
import ResetPassword from './pages/ResetPassword.jsx'
import ResetPasswordComplete from './pages/ResetPasswordComplete.jsx';
import AccountResetPassword from './pages/AccountResetPassword.jsx'
import WebChatComponent from './pages/WebChatComponent.jsx'
import SetNewPassword from './pages/SetNewPassword.jsx'
import CompleteSetPassword from './pages/CompleteSetPassword.jsx'
import SignUp from './pages/SignUp_Main.jsx'
import AccountSection from './pages/AccountSection.jsx';
import HelpSection from './pages/HelpSection.jsx';
import EditProfile from './pages/DoctorProfile.jsx'
import PaySection from './pages/PaySection';
import EmailVerification from './pages/EmailVerification.jsx';
import AboutUs from './pages/AboutUs.jsx'
import HowHealthyStart from './pages/questionsWeb/HowHealthyStart.jsx'
import QuestionPage from './pages/questionsWeb/QuestionPage.jsx';
import ResultPage from './pages/questionsWeb/ResultPage.jsx';

// const IP = "localhost";
const IP = process.env.REACT_APP_HOST;
const changeEmail = (email) => {
  return email.replace('@', '^');
};

const App = () => {
  const [selectedChannelUrl, setSelectedChannelUrl] = useState(null);
  const [email, setEmail] = useState(null);
  const [nickname, setNickname] = useState(null);
  const [profileUrl, setProfileUrl] = useState('')
  const navigate = useNavigate();
  const [language, setLanguage] = useState('en'); // 기본 언어 설정

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const response = await axios.get(`/api/session1`, {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        });
  
        if (response.status === 200) {  // 수정: response.ok에서 response.status === 200으로
          const data = response.data;  // 수정: response.json()에서 response.data로
          localStorage.setItem('loggedInEmail', data.email); // 이메일을 localStorage에 저장
        } else {
          console.log('No session found');
          navigate('/Login');
        }
      } catch (error) {
        console.error('Error fetching session:', error);
        if (error.response && error.response.status === 401) {
          // 인증 오류 시 로그인 페이지로 리디렉션
          navigate('/Login');
        }
      }
    };
  
    const loadingEmail = async () => {
      try {
        const response = await axios.get(`/api/session-email`, {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        });
        const data = response.data;
        localStorage.setItem('loggedInEmail', data.email); // 이메일을 localStorage에 저장
      } catch (error) {
        console.error('Error fetching session:', error);
      }
    };
  
    const loadingEmail1 = async () => {
      try {
        const response = await axios.get(`/api/nickname`, {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        });
        if (response.status === 200) {
          const data = response.data;
          setEmail(data.sbEmail);
          setNickname(data.nickname); 
          setProfileUrl(data.profileUrl);
          localStorage.setItem('loggedInEmail', data.email); // 이메일을 localStorage에 저장
        } else {
          console.log('No session found');
        }
      } catch (error) {
        console.error('Error fetching session:', error);
      }
    };
  
    loadingEmail();
    loadingEmail1();
    fetchSession();
  }, [navigate]);
  

  return (
    <SendbirdProviderWrapper userId={email} nickname={nickname} profileUrl={profileUrl}>
      <div className="app-container">
        <Routes>
          <Route path="/" element={<Start email={email}/>} />
          <Route path="/doctor-profile" element={<DoctorProfile />} />
          <Route
            path="/WebChat"
            element={
              <WebChatComponent
                selectedChannelUrl={selectedChannelUrl}
                onSelectChannel={setSelectedChannelUrl}
                email={email}
              />
            }
          />
        <Route path="/Login" element={<LoginPage />} />
        <Route path="/ResetPassword" element={<ResetPassword />} />
        <Route path="/AccountResetPassword" element={<AccountResetPassword />} />
        <Route path="/ResetPasswordComplete" element={<ResetPasswordComplete/>}/>
        <Route path="/SetNewPassword" element={<SetNewPassword />} />
        <Route path="/CompleteSetPassword" element={<CompleteSetPassword />} />
        <Route path="/SignUp" element={<SignUp />} />
        <Route path="/EditProfile" element={<DoctorProfile />} />
        <Route path="/account" element={<AccountSection email={email} />} />
        <Route path="/help" element={<HelpSection />} />
        <Route path="/pay" element={<PaySection email={email} />} />
        <Route path="/verify-email" element={<EmailVerification />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/HowHealthy" element={<HowHealthyStart setLanguage={setLanguage} />} />
        <Route path="/Question" element={<QuestionPage language={language} />} />
        <Route path="/Result" element={<ResultPage />} />
        </Routes>
      </div>
    </SendbirdProviderWrapper>
  );
};

export default App;
